import { useEffect } from 'react';
import TickImg from '../Assets/Images/thank-you-tick.png'
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import useWindowDimensions from '../Hooks/useWindowDimensions';

const CallRequestSuccess = () => {

  const { id }                 = useParams();

  // useEffect(() => { 
  // 	document.body.style.backgroundColor = "#343333";
  // },[]);

  return <>
    <div className="container call-request-success">
      <div className="details-container">
        <div className="tick"><img src={TickImg} alt="" /></div>
        <h1>You've successfully requested a call with us!</h1>
        <p>We've taken note of your request and will get in touch with you within 1-2 business days.</p>
        <p>Thank you for taking an intentional step toward activating the Great Commission!</p>
        <div className="back-btn">
          <Link to={"/results/" + id}>GO BACK TO MY RESULTS</Link>
        </div>
      </div>
    </div>
  </>
}


export default CallRequestSuccess;
